.privacy-policy-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
}

h1, h2 {
  color: #333;
}

p {
  margin: 0 0 20px;
}

ul {
  margin: 0 0 20px 20px;
}

address {
  font-style: normal;
  margin: 20px 0;
}

a {
  color: #007bff;
}
